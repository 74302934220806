/* General Styles */
body, #asosiy_template {
    font-family: Arial, sans-serif;
    color: #0a3d62;
    background-color: #f5f8fb;
    margin: 0;
    padding: 0;
}

/* Navbar */
#asosiy_navbar {
    background-color: #0a3d62;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    justify-content: space-between;
}

.navbar-logo {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
}

.navbar-logo img {
    height: 40px;
    margin-right: 10px;
}
.product-image {
    flex-direction: column;
}

/* Menu Toggle Button */
.menu-toggle {
    display: none; /* Hidden by default for larger screens */
    background: none;
    color: #ffffff;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
}

.navbar-links {
    display: flex;
    gap: 20px;
}

.navbar-links a {
    color: #f5f8fb;
    font-size: 1rem;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.navbar-links a:hover {
    background-color: #1e90ff;
    color: #ffffff;
}

.register-button a {
    color: #ffffff;
    background-color: #1e90ff;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
}

.register-button a:hover {
    background-color: #ffffff;
    color: #1e90ff;
}
.product-image {
    position: relative;
}
#home_card {
    display: "inline-flex";
    position: absolute;
    bottom: 50px;
    justify-content: center;
}

#home_card a {
    margin: 0 10px;
    background-color: #8d68e2;
    padding: 20px 30px;
    /* border-radius: 10px; */
    box-shadow: 1px 1px 10px #00000048;
    color: white;
    text-decoration: none;
    transition: 0.2s;
}
#home_card a:first-child {
    background-color: #d7ebff;
    color: #1e90ff;
}
#home_card a:hover {
    transform: translateY(10px);
    background-color: white;
    color: #1e90ff;
}
#home_card a:first-child:hover {
    transform: translateY(10px);
    background-color: #1e90ff;
    color: wheat;
}

#tg_bog{
    background-color: #00eeff;
    border-radius: 30px;
    transition: 1s;
    height: 60px;
    overflow: hidden;
    width: 60px;
    box-shadow: 3px 3px 10px #00000062;
    transition-delay: 1s;
}
#tg_bog:hover {
    transition: 0.4s;
    width: 476px;
    overflow: none;
    transition-delay: 0.1s;
}
#tg_bog h3{
    margin: auto 5px;
}
#block_click{
    position: absolute;
    right: 0;
    width: 60px;
    height: 100%;
    z-index: 1000;
    background-color: #000000e1;
}

#block_click {
    background-color: #00000000;
}
/* Mobile Responsive */
@media (max-width: 960px) {
    /* Show menu toggle button on mobile */
    .menu-toggle {
        display: block;
    }

    /* Hide navbar links by default on mobile */
    .navbar-links {
        display: none;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #0a3d62;
        padding: 20px;
    }

    /* Show navbar links when menu is open */
    .navbar-links.show {
        display: flex;
    }

    .navbar-links a {
        font-size: 1.2rem;
        padding: 10px;
        text-align: center;
    }
    
    #tg_bog{
        display: flex;
        flex-direction: column;
        background-color: #00eeff;
        border-radius: 30px;
        transition: 1s;
        height: 60px;
        overflow: hidden;
        width: 60px;
        box-shadow: 3px 3px 10px #00000062;
        transition-delay: 1s;
    }
    #tg_bog:hover {
        transition: 0.4s;
        height: 124px;
        width: 250px;
        overflow: none;
    }
}

@media (max-width: 480px) {
    .navbar-logo {
        font-size: 1.2rem;
    }

    .navbar-logo img {
        height: 30px;
        margin-right: 8px;
    }

    /* Footer font size */
    footer p {
        font-size: 0.8rem;
    }
}
