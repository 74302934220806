.Xarajatlar {
  padding: 20px;
}

.month-group {
  margin-bottom: 20px;
}

.transaction-cards {
  display: flex;
  flex-wrap: wrap;
  /* Float bilan ishlashni davom ettirish uchun containerga max-width qo'yish */
  max-width: 100%;
  overflow: hidden;
  /* Floated elementlarni to'g'ri ko'rsatish */
}

.transaction-card {
  max-width: 200px;
  margin: 10px 5px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.199);
  transition: transform 0.3s ease-in-out;
}

.positive {
  border: 1px solid rgb(115, 255, 0);
  background-color: rgb(180, 255, 211);
}

.negative {
  border: 1px solid rgba(255, 162, 162, 0.644);
  background-color: rgb(255, 208, 180);
}

.positive .amount {
  color: green;
}

.negative .amount {
  color: red;
}

.transaction-card:hover {
  transform: scale(1.05);
}

.amount {
  font-size: 1.2em;
  color: #333;
}

.bio {
  font-size: 0.9em;
  color: #777;
}

.date {
  font-size: 0.8em;
  color: #818181;
}

/* Clear float (to ensure proper layout after floating elements) */
.transaction-cards::after {
  content: "";
  display: block;
  clear: both;
}